require('./vendor/bootstrap');
require('./vendor/slick.min');
require('./vendor/flickity.pkgd.min.js');

//require('./animations-chris');
require('./animations');
require('./helpers');
require('./slider');
require('./scroll-slider');
require('./menu');
require('./blog');
require('./accordion');
(function(window, document, $, undefined) {

    'use strict';
    window.slider = {};

    slider.init = function () {
        slider.solutions();
        slider.portfolio();
        slider.portfolioMobile();
        slider.clients();
        slider.clientsMobile();

        slider.flickityInit();
    }

    slider.flickityInit = function () {

        /*$('.client-carousel').flickity({
            wrapAround: true,
            cellAlign: 'center',
        });*/


        var $solutionsCarousel = $('.solutions-carousel');
        $solutionsCarousel.flickity({
            cellAlign: 'center',
            pageDots: false,
            wrapAround: true
        });

        var $clientCarousel = $('.client-carousel');
        $clientCarousel.on( 'ready.flickity', function() {
            animations.clientsHoverOver();
        });
        $clientCarousel.flickity({
            cellAlign: 'center',
            pageDots: false,
            wrapAround: true
        });
    }

    slider.solutions = function() {

        $('.slider-solutions').slick({
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
        });
    }

    slider.portfolio = function() {

        $('.slider-portfolio').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            slidesToShow: 4,
        });
    }

    slider.portfolioMobile = function() {

        $('.slider-portfolio__mobile').slick({
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
        });
    }

    slider.clients = function() {

        $('.slider-clients').on('init', function(event, slick){

            animations.clientsHoverOver();

        });

        $('.slider-clients').slick({
            infinite: true,
            slidesToShow: 3,
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev">Previous <svg class="icon fab fa-button"><use xlink:href="/wp-content/themes/donahue-corry/images/spritemap.svg#sprite-icon-chevron-left"></use></svg></button>',
            nextArrow: '<button type="button" class="slick-next">Next <svg class="icon fab fa-button"><use xlink:href="/wp-content/themes/donahue-corry/images/spritemap.svg#sprite-icon-chevron-right"></use></svg></button>'
        });

        let reinit = false;

        $('.slider-clients').on('afterChange', function(event, slick, currentSlide, nextSlide){

            animations.clientsHoverOver();

            //element.classList.contains(class);

            if (!reinit) {

                reinit = true;
            }
           // slider.clientReset();



        });



    }



    slider.clientsMobile = function() {

        $('.slider-clientsMobile').slick({
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
        });
    }

    slider.clientReset = function() {

        const clients = gsap.utils.toArray('.clients .client-content-container');

        // Hover State
        clients.forEach(clients => {

            let container = clients.querySelectorAll('.client-testimonial-container');
            let text = clients.querySelectorAll('.client-testimonial-container p');
            let tl = gsap.timeline({ paused: true, onStart: function() {

                }
            });
            gsap.set(container, { x: 460, autoAlpha: 1 });
            gsap.set(text, { autoAlpha: 0, y: 25 });
            gsap.set(clients, { y: 25, autoAlpha: 0 });


            tl.to(container, { duration: .4, x: 0 });
            tl.to(text, { autoAlpha: 1, duration: .3, x: 0, y: 0 });

            clients.addEventListener("mouseover", () => tl.play());
            clients.addEventListener("mouseleave", () => tl.reverse());
        });

        /*const clients = gsap.utils.toArray('.clients .client-content-container');

        clients.forEach(clients => {

            gsap.set(clients, { autoAlpha: 1, y: 0 });

            let container = clients.querySelectorAll('.client-testimonial-container');
            let text = clients.querySelectorAll('.client-testimonial-container p');
            let tl = gsap.timeline({ paused: true });

            tl.to(container, { duration: .4, x: 0 });
            tl.to(text, { autoAlpha: 1, duration: .3, x: 0, y: 0 });

            clients.addEventListener("mouseover", () => tl.play());
            clients.addEventListener("mouseleave", () => tl.reverse());
        });*/
    }

    $(document).on( 'ready', slider.init );

})(window, document, jQuery);
(function(window, document, $, undefined) {

    'use strict';
    window.accordion = {};

    accordion.init = function () {
        accordion.iconAnimation();
    }

    accordion.iconAnimation = function() {

        $('.accordion').on('show.bs.collapse', function (e) {

            var icon = $("#" + e.target.id + '').prev().find('svg use');
            icon.attr('href', '/wp-content/themes/donahue-corry/images/spritemap.svg#sprite-icon-minus');
        });

        $('.accordion').on('hidden.bs.collapse', function (e) {

            var icon = $("#" + e.target.id + '').prev().find('svg use');
            icon.attr('href', '/wp-content/themes/donahue-corry/images/spritemap.svg#sprite-icon-plus');
        });

    }

    $(document).on( 'ready', accordion.init );

})(window, document, jQuery);
(function(window, document, $, undefined) {

    'use strict';
    window.animations = {};

    animations.init = function () {

        animations.appendURLParams();

        animations.twoColumnSlideIn();

        animations.latestBlogCategories();
        animations.latestBlog();
        animations.faqToggle();
        animations.faq();
        animations.homepageSlider();
        animations.statSlider();
        animations.headers();
        animations.paragraphs();
        animations.solutions();
        //animations.clientHoverOver();
        animations.clientFadeIn();
        //animations.clientsHoverOver();
        animations.portfolio();
        animations.requestInformation();
        animations.menu();

        //animations.reset();

        animations.paragraphFadeIn();
        animations.staggerFadeIn();
    }

    animations.twoColumnSlideIn = function() {

        const elements = gsap.utils.toArray('.two-column-slide-in');

        elements.forEach(element => {

            //const bannerLeft = element.find('.slide-in-left');
            const bannerLeft = element.querySelectorAll('.slide-in-left');
            //const bannerRight = element.find('.slide-in-right');
            const bannerRight = element.querySelectorAll('.slide-in-right');

            gsap.set(bannerLeft, { x: -35 });
            gsap.set(bannerRight, { x: 35 });
            gsap.set(element, { autoAlpha: 0 });

            const tl = gsap.timeline({ paused: true });

            //tl.to(bannerContainer, { autoAlpha: 1 });
            tl.to(element, { autoAlpha: 1 }, .2);
            tl.to(bannerLeft, { autoAlpha: 1, x: 0 }, 0);
            tl.to(bannerRight, { autoAlpha: 1, x: 0 }, 0);

            ScrollTrigger.create({
                trigger: element,
                start: "center bottom",
                toggleActions: 'play none none none',
                onEnter: () => tl.play()
            });

        });

        /*const bannerContainer = $('.two-column-slide-in');
        const bannerLeft = bannerContainer.find('.slide-in-left');
        const bannerRight = bannerContainer.find('.slide-in-right');

        gsap.set(bannerLeft, { x: -35 });
        gsap.set(bannerRight, { x: 35 });

        const tl = gsap.timeline({ paused: true });

        tl.to(bannerContainer, { autoAlpha: 1 });
        tl.to(bannerLeft, { autoAlpha: 1, x: 0 }, 0);
        tl.to(bannerRight, { autoAlpha: 1, x: 0 });

        ScrollTrigger.create({
            trigger: bannerContainer,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => tl.play()
        });*/
    }

    animations.staggerFadeIn = function() {

        const elements = gsap.utils.toArray('.stagger-fade-in');

        elements.forEach(element => {

            let items = element.querySelectorAll('.animate');

            gsap.set( items, { x: -25, autoAlpha: 0 });

            const animation = gsap.to( items, {
                duration: 1,
                x: 0,
                stagger: .2,
                autoAlpha: 1,
                paused: true,
            });

            ScrollTrigger.create({
                trigger: element,
                start: "center bottom-=120px",
                toggleActions: 'play none none none',
                onEnter: () => animation.play()
            });

            /*items.forEach(item => {


            });*/

        });


        /*elements.forEach(element => {


        });*/

    }

    animations.paragraphFadeIn = function() {

        const paragraph = gsap.utils.toArray('p.animate');
        gsap.set( paragraph, { y: 25, autoAlpha: 0 });

        paragraph.forEach(p => {

            const animation = gsap.to( p, {
                duration: 1,
                y: 0,
                autoAlpha: 1,
                paused: true,
            });

            ScrollTrigger.create({
                trigger: p,
                start: "center bottom-=100px",
                toggleActions: 'play none none none',
                onEnter: () => animation.play()
            });

        });

    }

    animations.menu = function() {

        const links = gsap.utils.toArray(['.nav-primary .menu-item a', '.title-area a']);

        links.forEach( link => {

            let animation = gsap.to( link.querySelectorAll('span'), {
                duration: .25,
                paused: true,
                width: "100%"
            });

            link.addEventListener("mouseover", () => animation.play());
            link.addEventListener("mouseleave", () => animation.reverse());
        });

        /*gsap.registerPlugin(CSSRulePlugin);

        *!/

            let line = CSSRulePlugin.getRule(".menu-item a::before");
            const tl = gsap.timeline({});
            tl.to( line, { cssRule: { width: "100%" } });

            link.addEventListener("mouseover", () => tl.play());
            link.addEventListener("mouseleave", () => tl.reverse());
        });*/

        /*const links = document.querySelectorAll('.nav-primary li')

        links.forEach(function(link){
            const rule = CSSRulePlugin.getRule(`a::after`, link);

            gsap.to(rule, {
                duration: 1,

                cssRule: {
                    width: 100,
                }
            })
        });*/
    }



    animations.appendURLParams = function() {

        const url = window.location.search;
        const params = new URLSearchParams(url);
        const version = params.get('version')

        if (version) {

            $('a').each( function() {

                let href = $(this).attr('href');
                $(this).attr('href', href + '?version=' + version);
            })
        }
    }

    animations.latestBlogCategories = function() {

        const blog = gsap.utils.toArray('.blog-container .row > div ');

        gsap.set(blog, { y: 25, autoAlpha: 0 });

        const animation = gsap.to( blog, {
            duration: 1,
            y: 0,
            stagger: .2,
            autoAlpha: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: blog,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });
    }

    animations.latestBlog = function() {

        let rightPanel = $('.latest-blog-posts');
        let leftPanel = $('.latest-blog-posts-container');

        gsap.set(leftPanel, { x: -40 })
        gsap.set(rightPanel, { x: 40 })

        gsap.to(rightPanel, { x: 0, autoAlpha: 1, duration: .6 }, 0);
        gsap.to(leftPanel, { x: 0, autoAlpha: 1, duration: .6 }, .4);


    }

    animations.faqToggle = function() {

        /*const myCollapsible = document.getElementsByClassName('accordion-item')
        myCollapsible.addEventListener('hidden.bs.collapse', event => {
            console.log('truggy');
        })*/

        gsap.set('.accordion-body', { autoAlpha: 0, y: -40, scale: .4 });

        $('.accordion').on('show.bs.collapse', function (e) {
            gsap.to('#' + e.target.id + ' .accordion-body', { autoAlpha: 1, duration: .5, y: 0, scale: 1, ease: "expo.inOut" });
        });

        $('.accordion').on('hide.bs.collapse', function (e) {
            gsap.to('#' + e.target.id + ' .accordion-body', { autoAlpha: 0, duration: .3, y: -40, scale: .4, ease: "expo.inOut" });
        });

    }

    animations.faq = function() {

        let faqItems = $('.accordion-item');

        gsap.set(faqItems, { scale: .8, y: 45 })

        const animation = gsap.to(faqItems, {
            duration: .5,
            scale: 1,
            y: 1,
            stagger: .06,
            autoAlpha: 1,
            paused: true,
            ease: "power1.inOut",
            onComplete: function()
            {
                animations.faqHover();
            }
        });

        ScrollTrigger.create({
            trigger: faqItems,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });


    }

    animations.faqHover = function() {

        const faqs = gsap.utils.toArray('.page-faqs .accordion .accordion-item');

        // Hover States
        faqs.forEach(faqs => {

            let animation = gsap.to( faqs, {
                //delay: .5,
                duration: .5,
                scale: 1.035,
                paused: true,
                zIndex: 10,
            });

            faqs.addEventListener("mouseover", () => animation.play() );
            faqs.addEventListener("mouseleave", () => animation.reverse());
        });
    }

    animations.reset = function() {

        let header = $('.site-header');

        header.prepend('<button id="reset-animations" style="position: absolute; left: auto; right: 0; z-index: 9999;">Reset</button>');

        $('#reset-animations').on('click', function() {
            console.log('reset');
            animations.portfolio(true);
        });
    }

    /*animations.portfolio = function( reset = false) {

        const portfolio = gsap.utils.toArray('.portfolio .col-md-4');

        gsap.set(portfolio, { y: 25, autoAlpha: 0 });

        const animation = gsap.to(portfolio, {
            duration: 1,
            y: 0,
            stagger: .2,
            autoAlpha: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: portfolio,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });

    }*/

    animations.portfolio = function() {

        const portfolio = gsap.utils.toArray('.portfolio .col-md-4');

        gsap.set(portfolio, { y: 25, autoAlpha: 0 });

        const animation = gsap.to(portfolio, {
            duration: 1,
            y: 0,
            stagger: .2,
            autoAlpha: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: portfolio,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });

        // Hover States
        portfolio.forEach(portfolio => {

            let animation = gsap.to( portfolio, {
                duration: .5,
                paused: true,
                scale: 1.1,
                zIndex: 10,
            });

            portfolio.addEventListener("mouseover", () => animation.play());
            portfolio.addEventListener("mouseleave", () => animation.reverse());
        });

    }

    animations.clientHoverOver = function() {

        const clients = gsap.utils.toArray('.clients .client-content-container');

        clients.forEach( client => {

            let animation = gsap.to( client, {
                duration: .5,
                paused: true,
                scale: 1.1,
                zIndex: 10,
            });

            client.addEventListener("mouseover", () => animation.play());
            client.addEventListener("mouseleave", () => animation.reverse());
        });
    }

    animations.clientFadeIn = function() {

        const clients = gsap.utils.toArray('.clients .client-content-container');

        const animation = gsap.to(clients, {
            duration: 1,
            y: 0,
            stagger: .2,
            autoAlpha: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: clients,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });
    }

    animations.clientsHoverOver = function() {

        const clients = gsap.utils.toArray('.clients .client-content-container');


        // Hover State
        clients.forEach(client => {

            let container = client.querySelector('.client-testimonial-container');
            let text = client.querySelector('.client-testimonial-container p');

            let containerWidth = container.offsetWidth;

            console.log(containerWidth);
            
            gsap.set(container, { x: containerWidth, autoAlpha: 0 });
            gsap.set(text, { autoAlpha: 0, y: 25 });
            let tl = gsap.timeline({ paused: true });

            /*gsap.set(container, { x: 460, autoAlpha: 1 });
            gsap.set(text, { autoAlpha: 0, y: 25 });*/
            //gsap.set(clients, { y: 25, autoAlpha: 0 });

            tl.to(container, { duration: .4, x: 0, autoAlpha: 1 });
            tl.to(text, { autoAlpha: 1, duration: .3, x: 0, y: 0 });

            client.addEventListener("mouseover", () => tl.play());
            client.addEventListener("mouseleave", () => tl.reverse());
        });

    }

    animations.solutions = function() {

        const allSolutions = gsap.utils.toArray('.solutions .row a');
        let active = false;

        gsap.set(allSolutions, { y: 25, autoAlpha: 0 });

        const animation = gsap.to(allSolutions, {
            duration: 1,
            y: 0,
            stagger: .2,
            autoAlpha: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: allSolutions,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });

        // Hover States
        allSolutions.forEach(solutions => {

            console.log(solutions.dataset.index);

            let background = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content');
            let header = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content h2');
            let text = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content p');
            let slideIn = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content .rightSlideIn');

            gsap.set( slideIn, { x: 135 });
            gsap.set( header, { y: header.height() });
            gsap.set( background, { backgroundColor: "rgba(0, 0, 0, .15)" });

            let animation = gsap.timeline({paused: true})

            animation
                //.to( solutions, { duration: .5, scale: 1.1, zIndex: 10 })
                .to( header, { color: "#333", textShadow: 'none', y: 0 }, .2)
                .to( text, { duration: .3, autoAlpha: 1, color: "#333" }, .5)
                .to( background, { backgroundColor: "rgba(255, 255, 255, .8)" }, .3)
                .to( slideIn, { x: 0 }, .2);

            solutions.addEventListener("mouseover", () => {

                if (active) {
                    animation.play()
        
                } else {
                    animation.play()
                }

            });
            solutions.addEventListener("mouseleave", () => {
                animation.reverse()
            });



        });


        /*allSolutions.forEach(solutions => {

            console.log(solutions.dataset.index);

            let background = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content');
            let header = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content h2');
            let text = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content p');
            let slideIn = $('a[data-index="' + solutions.dataset.index + '"] .solutions-content .rightSlideIn');

            gsap.set( slideIn, { x: 135 });
            //gsap.set( text, { y: 135 });

            //let textOffset = background.height() - ( text.height() + header.height() ) ;

            let animation = gsap.timeline({paused: true})

            animation
                //.to( solutions, { duration: .5, scale: 1.1, zIndex: 10 })
                .to( header, { color: "#333", textShadow: 'none' , onStart: function() {

                        if (header.height() > 30 ) {
                            animation.to( header, {y: -70}, .2);
                        } else {
                            animation.to( header, {y: -56}, .2);
                        }
                    }
                }, .2)
                //.to( text, { y: textOffset }, 0)
                .to( text, { duration: .3, autoAlpha: 1, color: "#333" }, .7)
                .to( background, { backgroundColor: "rgba(255, 255, 255, .6)" }, .4)
                .to( slideIn, { x: 0 }, .2);

            solutions.addEventListener("mouseover", () => {

                if (active) {
                    animation.play()

                } else {
                    animation.play()
                }

            });
            solutions.addEventListener("mouseleave", () => {
                animation.reverse()
            });



        });*/

    }

    animations.homepageSlider = function() {

        //gsap.registerPlugin(CSSRulePlugin);

        //const leftSideTest = CSSRulePlugin.getRule(".slider-container::before");

        //console.log(leftSideTest);

        const leftSide = $('.slider-container .slideLeft');
        const rightSide = $('.slider-container .slideRight');
        const sliderText = $('.slider-container .slider-content p');
        const background = $('.slider-container .slider-image');
        let leftSideOffset = 0;
        let width = helpers.getWidth();

        if (width < 768) {
            leftSideOffset = -320;
        } else {
            leftSideOffset = -440;
        }

        gsap.set(leftSide, { x: -width - 750, y: 68, duration: .5, autoAlpha: .6 });
        gsap.set(rightSide, { x: 550, duration: .5, autoAlpha: 1 });
        gsap.set(".slider-content p", {x: -70 });

        let tl = gsap.timeline({});

        tl.to( background, { scale: 1.1, duration: 12 }, 0);
        //tl.from( background, { opacity: 0, duration: 1 }, "<", 0);
        tl.to( leftSide, { delay: .1, x: leftSideOffset, ease: "expo.inOut", duration: .6 }, 0);
        tl.to( rightSide, { delay: .1, x:0, ease: "expo.inOut", duration: .6 }, 0);
        tl.to( sliderText, { x: 0, ease: "expo.inOut", duration: 1.2, autoAlpha: 1 }, .5);
    }

    animations.statSlider = function() {

        gsap.registerPlugin(ScrollTrigger);

        let value = $('.statistics .value');

        var onLoadAnimation = gsap.timeline();

        value.each(function (index) {

            let dataValue = $(this).data('value');
            let dataType = $(this).data('type');
            let getValue = $(this).closest('.value');
            let number = $(this).closest('.number');

            let element = {
                val: 0
            };

            onLoadAnimation.to(element, 4, {
                val: dataValue,
                roundProps: 'val',
                onUpdate:
                    function () {
                        gsap.to(number, .4, {autoAlpha: 1});
                        getValue.html(element.val);
                    },
                ease: Power4.easeOut,

            }, 0);

            ScrollTrigger.create({
                trigger: '.statistics',
                start: "center bottom-=100px",
                animation: onLoadAnimation,
                toggleActions: 'play none none none',
            })
        });
    }

    animations.headers = function() {

        const headers = gsap.utils.toArray(['h2.animate', 'h1.animate', 'h1.entry-title']);

        gsap.set(headers, { y: 25, autoAlpha: 0 });

        headers.forEach(header => {
            const anim = gsap.to(header, {y: 0, autoAlpha: 1, ease: "power1.out", paused: true, duration: .7});

            ScrollTrigger.create({
                trigger: header,
                start: "center bottom-=100px",
                toggleActions: 'play none none none',
                onEnter: () => anim.play()
            });
        });
    }

    animations.paragraphs = function()
    {
        const paragraph = gsap.utils.toArray('section > p');

        gsap.set(paragraph, { y: 25, autoAlpha: 0 });

        paragraph.forEach(paragraph => {
            const anim = gsap.to(paragraph, {y: 0, autoAlpha: 1, ease: "power1.out", paused: true, duration: .7});

            ScrollTrigger.create({
                trigger: paragraph,
                start: "center bottom-=100px",
                toggleActions: 'play none none none',
                onEnter: () => anim.play()
            });
        });
    }

    animations.requestInformation = function() {

        gsap.registerPlugin(ScrollTrigger);

        const section = $('.request-information .row');

        gsap.set( section, { x: -25, autoAlpha: 0 });

        const animation = gsap.to( section, {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            paused: true,
        });

        ScrollTrigger.create({
            trigger: section,
            start: "center bottom",
            toggleActions: 'play none none none',
            onEnter: () => animation.play()
        });

    }



    $(document).on( 'ready', animations.init );

})(window, document, jQuery);
(function(window, document, $, undefined) {

    'use strict';
    window.blog = {};

    blog.init = function () {
        blog.category();
    }

    blog.category = function() {

        let clickActive = 1;

        $('.blog-menu-filter').on('click', 'button.category', function(e) {
            e.preventDefault();

            if (clickActive) {

                blog.disable();
                blog.ajax();
                blog.enable();
                blog.menu_update( $(this) );

                //$('.blog-menu-filter button').prop('disabled', true);

                clickActive = 0;
                setTimeout(function() {
                    //$('.blog-menu-filter button').prop('disabled', false);
                    clickActive = 1;
                }, 1000);
            }

        });
    }

    blog.disable = function() {

        let setHeight = $('.blog-container .row').height();

        //
        $('.blog-container .row').height( setHeight );
        $('.blog-container .container').prepend('<div class="blog-loader">Loading...</div>');
        $('.blog-container .row').css('opacity', '0');
    }

    blog.enable = function() {

        $('.blog-container .blog-loader').fadeOut();
       // $('.blog-menu-filter button').prop('disabled', false);

    }

    blog.menu_update = function( button ) {

        $('.blog-menu-filter button').removeClass('active');
        button.addClass('active');
    }

    function preload(arrayOfImages) {
        $(arrayOfImages).each(function(){
            $('<img/>')[0].src = this;
            // Alternatively you could use:
            // (new Image()).src = this;
        });
    }

    blog.ajax = function() {

        var apiEndpoint = 'https://dc-v2.davisadbeta.com/wp-json/wp/v2/posts';

        $.ajax({
            url: apiEndpoint,
            type: 'GET',
            dataType: 'json',
            /*beforeSend: function () {
                $('.blog-menu-filter button').prop('disabled', true);
            },*/
            success: function (data) {
                // Handle the successful response here
                console.log('API Response:', data);

                //$('.blog-menu-filter button').delay(2000).prop('disabled', false);

                let layout = '';

                // You can loop through the data and do something with each blog post
                for (var i = 0; i < data.length; i++) {
                    var post = data[i];
                    let options = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
                    let date = new Date(post['date']).toDateString();

                    let datestring = date.split(' ');
                    let formatted_date = datestring[1] + ' ' + Number(datestring[2]) + ', ' + datestring[3];

                    //let datestring = date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();

                    //let datestring = date.toLocaleDateString("en-US", options);

                    //console.log(post);

                    layout += `<div class="col-md-4 mt-5 mb-4 position-relative">
                    <div class="blog-image">
                        <p class="category">Category</p>
                        <img src="` + post['featured_image'] + `" />
                    </div>
                    <a href="#">
                        <div class="blog-content">
                            <h3 class="title mb-3">` + post['title']['rendered'] + `</h3>
                            ` + post['excerpt']['rendered'] + `
                            <p class="date mb-0">` + formatted_date + `</p>
                        </div>
                    </a>
                </div>`;

                }

                /*preload([
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-1.jpg',
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-2.jpg',
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-3.jpg',
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-4.jpg',
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-5.jpg',
                    'https://dc-v2.davisadbeta.com/wp-content/uploads/2024/03/blog-placeholder-6.jpg'
                ]);*/

                //$('.blog-container').html('');
                $('.blog-container .row').html(layout).animate({ opacity: 1 }, 500);
            },
            error: function (error) {
                // Handle errors here
                console.error('Error:', error);
            }
        });

    }

    $(document).on( 'ready', blog.init );

})(window, document, jQuery);

(function(window, document, $, undefined) {

    'use strict';
    window.menu = {};

    menu.init = function () {
        menu.closeButton();
        menu.mobile();
        menu.toggleAnimations();
        menu.mobileAccessibilty();
    }

    menu.closeButton = function()
    {
        setTimeout(() => {
            let overlay = $('.moby-overlay');
            overlay.append('<div class="moby-close"><span class="moby-close-icon"></span></div>');
        }, '200');

    }

    menu.mobileAccessibilty = function() {

        var $activeTrigger = $('#moby-button');

        $activeTrigger.on('click', function() {

            if (!$activeTrigger.hasClass('is-active')) {
                $activeTrigger.addClass('is-active');
                $activeTrigger.attr("aria-expanded","true");
            } else {
                $activeTrigger.removeClass('is-active');
                $activeTrigger.attr("aria-expanded","false");
            }

        });

        var menuItems = document.querySelectorAll('li.has-submenu');
        Array.prototype.forEach.call(menuItems, function(el, i){
            el.querySelector('.moby-expand').addEventListener("click",  function(event){
                if (this.closest('.has-submenu').className == "has-submenu open") {
                    this.closest('.has-submenu').className = "has-submenu";
                    this.setAttribute('aria-expanded', "false");
                } else {
                    this.closest('.has-submenu').className = "has-submenu open";
                    this.setAttribute('aria-expanded', "true");
                }
                event.preventDefault();
                return false;
            });
        });

    }

    menu.toggleAnimations = function() {

        $('.moby-expand').on('click', function() {
            if ($(this).hasClass('moby-submenu-open')) {
                gsap.to( $(this).find('svg'), {duration: .3, rotate: 0, transformOrigin:"50% 50%"});
            } else {
                gsap.to( $(this).find('svg'), {duration: .3, rotate: -90, transformOrigin:"50% 50%"});
            }
        });

    }

    menu.mobile = function () {

        setTimeout(function(){

            var template  = '<div class="moby-inner">';
            //template +=     '<div class="moby-close"><span class="moby-close-icon"></span></div>'; // Reserved class for closing moby
            template +=     '<div class="moby-wrap">';
            template +=         '<div>';
            template +=             '<img src="/wp-content/uploads/2024/03/donahue-corry-logo.png">';
            template +=             '<div class="moby-menu"></div>';
            template +=         '</div>';
            template +=     '</div>';
            template += '</div>';

            var mobyMenu = new Moby({
                menuClass        : 'right-side',
                breakpoint		 : 768,
                subMenuOpenIcon  : '<svg class="icon fab fa-triangle-solid"><use xlink:href="/wp-content/themes/lauren-jillian/images/spritemap.svg#sprite-chevron-left-light-white"></use></svg>',
                subMenuCloseIcon  : '',
                menu       : $('#mobile-main-navigation'), // The menu that will be cloned
                mobyTrigger: $('#moby-button'), // Button that will trigger the Moby menu to open
                template : template,
            });

            $('.process-link a').click(function() {

                mobyMenu.closeMoby();

                $("html").animate({
                        scrollTop: $('.process').offset().top
                    },
                    800
                );
            });


        }, 500);


    }

    $(document).on( 'ready', menu.init );

})(window, document, jQuery);
(function(window, document, $, undefined) {

    'use strict';
    window.helpers = {};

    helpers.init = function () {
        helpers.getHeight();
        helpers.getWidth();
    }

    helpers.getHeight = function() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }

    helpers.getWidth = function() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    $(document).on( 'ready', helpers.init );

})(window, document, jQuery);
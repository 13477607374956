(function(window, document, $, undefined){

    'use strict';
    window.scrollSlider = {};

    scrollSlider.init = function() {
        scrollSlider.slider();
    }

    scrollSlider.slider = function() {

        const dur = 50;

        document.querySelectorAll('.js-ticker .wrapper').forEach(ticker => {
            // Get the initial size of the ticker
            const totalDistance = $(ticker).width();

            // Position the ticker
            //gsap.set(ticker, {yPercent: -50});

            // Clone the first item and add it to the end
            $(ticker).append($(ticker.querySelector("li")).clone());

            // Get all of the items
            const items = ticker.querySelectorAll("li");

            const anim = gsap.to(ticker, {
                duration: dur,
                x: -totalDistance,
                ease: "none",
                repeat: -1,
                delay: .5,
            });

            let startPos;
            const wrap = gsap.utils.wrap(0, 1);

            gsap.registerPlugin(Draggable);

            const draggable = new Draggable(ticker, {
                type: "x",
                trigger: ticker,
                throwProps: true,
                onPressInit: function() {
                    anim.pause();
                    startPos = this.x;

                    $('.alert .toggle').data('status', 'pause');
                    $('.alert .toggle').find('span').html('<svg class="icon fab fa-play"><use xlink:href="/wp-content/themes/akf/images/spritemap.svg#sprite-play-solid"></use></svg>');
                },
                onDrag: function() {
                    let prog = wrap(-this.x / totalDistance);
                    anim.progress(prog);

                    $('.alert .toggle').data('status', 'play');
                    $('.alert .toggle').find('span').html('<svg class="icon fab fa-pause"><use xlink:href="/wp-content/themes/akf/images/spritemap.svg#sprite-pause-solid"></use></svg>');
                },
                onThrowUpdate: function() {
                    let prog = wrap(-this.x / totalDistance);
                    anim.progress(prog);
                },
                onThrowComplete: function() {
                    anim.play();
                    gsap.fromTo(anim, {timeScale:0}, {duration: 1, timeScale:1, ease:"none"});
                },
            });

            $('.alert .toggle').on('click', function() {
                let status = $(this).data('status');

                if (status == 'play') {
                    anim.pause();
                    $(this).data('status', 'pause');
                    $(this).find('span').html('<svg class="icon fab fa-play"><use xlink:href="/wp-content/themes/akf/images/spritemap.svg#sprite-play-solid"></use></svg>');
                } else if (status == 'pause') {
                    anim.play();
                    $(this).data('status', 'play');
                    $(this).find('span').html('<svg class="icon fab fa-pause"><use xlink:href="/wp-content/themes/akf/images/spritemap.svg#sprite-pause-solid"></use></svg>');
                }
            })

        });

    }

    $(document).on( 'ready', scrollSlider.init );


})(window, document, jQuery);